<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div>
    <mt-header title="收入凭证">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="invoice-apply-income" v-if="this.list.length > 0">
      <ImagePreview v-for="(item, index) in this.list" :key="index" :imgUrl="item" alt="收入凭证" />
    </div>
    <p v-else class="empty">
      暂无收入凭证信息
    </p>
  </div>
</template>

<script>
import ImagePreview from '@components/image-preview';
export default {
  name: "invoice-apply-income",
  props: ['urls'],
  components: {
    ImagePreview
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.list = decodeURIComponent(this.urls).split(',');
  },
  beforeDestroy() {},
  computed: {},
  methods: {}
};
</script>

<style lang="less" scoped>
.invoice-apply-income {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 3.4667vw 4.8vw;
  box-sizing: border-box;
}
.empty {
  text-align: center;
  margin: 24vw 0;
  font-size: 4.9333vw;
  font-weight: bold;
  color: rgba(51,51,51,1);
}
</style>